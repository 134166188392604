import React, {useEffect, useState} from "react"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi";
import { AccountLogin, StaticContext } from "../domain/interfaces";
import Page from "../templates/page"
import { navigate } from 'gatsby'

const ApiGateway = new httpApiGateway()

export default function Account(props: any) {

  const api = new httpApiGateway()
  const [context, setContext] = useState<StaticContext | null>(null)
  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
  }, []) // given empty array useEffect run only once

  const [form, setForm] = useState<AccountLogin>({
    email: "",
    password: "",
  })
  const [status, setStatus] = useState<{
    state: 'idle' | 'loading' | 'success' | 'error',
    error?: String
  }>({
    state: 'idle'
  })

  const handleChange = (key: string) => (event: any) => {
    setForm({...form, [key]: event.target.value})
  }

  const onConfirm = async () => {
    let response = await api.onLogin(form)
    if (response.error) {
      setStatus({
        state: 'error',
        error: response.error
      })
    } else {
      setStatus({
        state: 'success',
        error: undefined
      })
      navigate('/account')
    }
  }

  return <Page pageContext={context}>
    <div className="pt-20 md:py-24 flex flex-col items-center">
      
      <div className="w-full text-center">
        <h1 className="text-brand-primary text-2xl font-bold"> Connectez vous pour pouvoir passer commande </h1>
        <div className="flex flex-col items-center">
            <div className="py-1 text-gray-700"> Vous n'avez pas de compte ? </div>
            <a href="/signup" className="cursor-pointer text-brand-primary font-bold text-md">
                Je crée mon compte 
            </a>
        </div>
       </div>

       <div className="flex flex-col md:flex-row mx-auto">
        <img className="mx-10 object-cover" src="/login-page.png" />
        <div className="mt-5 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col w-full md:w-96 mx-auto">
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Mon adresse email
              </label>
              <input value={form.email} onChange={handleChange('email')} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="username" type="email" placeholder="chamatini@mail.com" />
            </div>
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Mon mot de passe
              </label>
              <input value={form.password} onChange={handleChange('password')} className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker" id="password" type="password" placeholder="******************" />
            </div>
            { status.state == 'error' && <div className="mb-4 flex m-1 font-medium py-1 px-2 bg-white rounded-md text-red-700 bg-red-100 border border-red-300 ">
              <div className="text-md font-normal max-w-full flex-initial">
                  {status.error}
              </div>
            </div> }
            <div className="flex space-x-4">
              <button onClick={onConfirm} className="bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded" type="button">
                Je me connecte
              </button>
            </div> 
        </div> 
        </div>
    </div>

  </Page>
}
